<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator/archive/floor/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="$auth('批量修改电梯楼层')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.batch.open(selectList)">
        {{$l("common.add", "批量修改电梯楼层")}}
      </el-button>

      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>
      <el-button
        v-if="$auth('常规信息导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>

      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="出厂编号">
            <el-input v-model.trim="filter.factoryCode" clearable></el-input>
          </vm-search>
          <vm-search label="电梯在使用单位内部编号">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="电梯启用日期">
            <el-date-picker
              v-model="filter.factoryDate"
              type="date"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期">
            </el-date-picker>
          </vm-search>
          <vm-search v-if="senior" label="使用类型">
            <vm-dict-select v-model="filter.useType" type="elevatorUseType"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="控制系统">
            <vm-dict-select v-model="filter.controlSystem" type="elevatorControlSystem"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="是否有机房">
            <el-select v-model="filter.machineRoom">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search v-if="senior" label="电梯品牌">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="电梯型号">
            <el-input v-model.trim="filter.modelName" clearable></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘楼宇" align="center" width="170">
        <template slot-scope="scope">
          {{scope.row.realEstateName+'-'+scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="电梯在使用单位内部编号" align="center" width="170"></el-table-column>
      <el-table-column prop="aboveGroundFloorCount" label="地上楼层" align="center" width="170"></el-table-column>
      <el-table-column prop="underGroundFloorCount" label="地下楼层" align="center" width="170"></el-table-column>
      <el-table-column prop="machineRoom" label="是否有机房" align="center">
        <template slot-scope="scope">
          {{scope.row.machineRoom?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="totalFloor" label="电梯层数" align="center"></el-table-column>
      <el-table-column prop="totalStation" label="电梯站数" align="center"></el-table-column>
      <el-table-column prop="totalDoor" label="电梯门数" align="center"></el-table-column>
      <el-table-column prop="machineRoom" label="是否有楼层对照" align="center">
        <template slot-scope="scope">
          {{scope.row.floorMapping?"是":"否"}}
        </template>
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <el-button v-if="$auth('层站楼详情')" type="primary" @click="$refs.elevatorDetail.open(scope.row.id)">{{$l("common.edit", "详情")}}</el-button>
          <!--          <el-button v-if="$auth('常规信息删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>-->
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
    <elevator-batch ref="batch" @save-success="getList(-1)"></elevator-batch>
  </div>
</template>
<script>
  import ElevatorBatch from "./ElevatorBatch.vue";
  import EditPage from "./ElevatorEdit.vue";
  import ElevatorDetail from "./ElevatorDetail";
  import {export2Excel, getWidth} from "@/util";
  import print from  "print-js";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {ElevatorBatch,EditPage,ElevatorDetail},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          factoryDate:"",
          realEstateName:"",
          maintenanceType:"as_need",
          companyId: loginUtil.getCompanyCode(),
        },
        senior: false,
        data:[],
        width:200,
        selectList:[],
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      handleSelectionChange(val) {
        this.selectList = val;
      },
      sort() {
        this.filter.orderColumnName="useStatus";
        this.filter.asc = this.asc;
        this.asc = this.asc?0:1;
        this.getList();
      },
      exportExcelData() {
        const title={
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"电梯在使用单位内部编号",
          regCode:"注册代码",
          aboveGroundFloorCount:"地上楼层",
          underGroundFloorCount:"地下楼层",
          machineRoom:"是否有机房",
          totalFloor:"电梯层数",
          totalStation:"电梯站数",
          totalDoor:"电梯门数",
          floorMapping:"是否有楼层对照",
        };
        this.$http.get("elevator/archive/floor/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`电梯档案-层站楼-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.regCode}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.regCode + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      goToDetail(elevatorId) {
        this.$router.push({path:"/elevator/Elevatordetail",query:{elevatorId}});
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
    .treeBox{
        border: 1px solid #E6E6E6;
        padding: 10px;
        margin-right: 10px;
        width: calc(100% - 1300px);
        max-height: 1080px;
        overflow-y: auto;
    }
</style>
