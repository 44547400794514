<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('电梯档案常规信息')" label="常规信息" name="first"><elevator-list></elevator-list></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯档案远程监测')" label="远程监测" name="second"><elevator-remote-list></elevator-remote-list></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯档案累计数据')" label="累计数据" name="third"><elevator-data-statistic></elevator-data-statistic></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯档案层站楼')" label="层站楼" name="fourth"><elevator-floor-list></elevator-floor-list></el-tab-pane>
    <!--    <el-tab-pane v-if="$auth('电梯档案层站楼')" label="运行报告" name="fifth"><elevator-report></elevator-report></el-tab-pane>-->
  </el-tabs>
</template>

<script>
  import ElevatorList from "@/views/elevator/ElevatorList";
  import ElevatorRemoteList from "@/views/elevator/ElevatorRemoteList";
  import ElevatorDataStatistic from "./ElevatorDataStatistic";
  import ElevatorFloorList from "./ElevatorFloorList";
  // import ElevatorReport from "@/views/elevator/ElevatorReport";
  export default {
    components:{ ElevatorRemoteList,ElevatorList,ElevatorDataStatistic,ElevatorFloorList},
  };
</script>

<style scoped>

</style>
