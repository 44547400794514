<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator/archive/common/page">
      <el-button
        v-if="$auth('常规信息新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>

      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>
      <el-button
        v-if="$auth('常规信息导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>

      <el-button
        v-if="$auth('常规信息导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="出厂编号">
            <el-input v-model.trim="filter.factoryCode" clearable></el-input>
          </vm-search>
          <vm-search label="电梯在使用单位内部编号">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="电梯状态">
            <el-select v-model="filter.elevatorStatus">
              <el-option :value="true" label="启用"></el-option>
              <el-option :value="false" label="禁用"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="屏蔽故障">
            <el-select v-model="filter.faultMasked">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="楼盘禁用">
            <el-select v-model="filter.realEstateStatus">
              <el-option :value="true" label="未禁用"></el-option>
              <el-option :value="false" label="已禁用"></el-option>
            </el-select>
          </vm-search>
          <vm-search v-if="senior" label="电梯启用日期">
            <el-date-picker
              v-model="filter.factoryDate"
              type="date"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期">
            </el-date-picker>
          </vm-search>

          <vm-search v-if="senior" label="使用类型">
            <vm-dict-select v-model="filter.useType" type="elevatorUseType"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="控制系统">
            <vm-dict-select v-model="filter.controlSystem" type="elevatorControlSystem"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="是否有机房">
            <el-select v-model="filter.machineRoom">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>

          <vm-search v-if="senior" label="电梯品牌">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="电梯型号">
            <el-input v-model.trim="filter.modelName" clearable></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘楼宇" align="center" width="170">
        <template slot-scope="scope">
          {{scope.row.realEstateName+'-'+scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="电梯在使用单位内部编号" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="regDate" label="注册日期" align="center" width="170"></el-table-column>
      <el-table-column prop="useTypeDesc" label="用途分类" align="center"></el-table-column>

      <el-table-column prop="brandName" label="品牌/型号" align="center">
        <template slot-scope="scope">
          {{scope.row.brandName+'/'+scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="factoryCode" label="出厂编号" align="center"></el-table-column>
      <el-table-column prop="useStatusDesc" label="电梯使用状态" align="center">
        <template slot="header" slot-scope="">
          电梯使用状态 <el-button type="text" icon="el-icon-sort" @click="sort"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorStatus" label="电梯是否启用" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorStatus?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="faultMasked" label="是否故障屏蔽" align="center">
        <template slot-scope="scope">
          {{scope.row.faultMasked?"是":"否"}}
        </template>
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <!--          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>-->
          <el-button v-if="$auth('常规信息详情')" type="primary" @click="$refs.elevatorDetail.open(scope.row.id)">{{$l("common.edit", "详情")}}</el-button>
          <el-button v-if="$auth('常规信息详情')" type="primary" @click="changeMaintianType(scope.row.id)">{{$l("common.edit", "转按规")}}</el-button>
          <el-button v-if="$auth('电梯状态修改')&&!scope.row.elevatorStatus" type="primary" @click="change(scope.row.id)">{{$l("common.edit", "启用")}}</el-button>
          <el-button v-if="$auth('电梯状态修改')&&scope.row.elevatorStatus" type="danger" @click="change(scope.row.id)">{{$l("common.edit", "停用")}}</el-button>
          <!--          <el-button-->
          <!--            :title="!scope.row.iotDeviceCode?'未绑定终端设备':scope.row.online?'在线':'离线'"-->
          <!--            type="success"-->
          <!--            @click="gotoMonitor(scope.row.id)">-->
          <!--            {{$l("monitor.monitor", "监控")}}-->
          <!--          </el-button>-->
          <!--          <el-button type="primary" @click="goToDetail(scope.row.id)">{{$l("common.detail", "详情")}}</el-button>-->
          <el-button v-if="$auth('常规信息删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
    <change-type-edit ref="changeTypeEdit" @save-success="getList(-1)"></change-type-edit>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/elevatorArchive.xlsx"
        action="elevator-archive/import"
        name="常规信息导入"
        download-name="常规信息.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./ElevatorEdit.vue";
  import ElevatorDetail from "./ElevatorDetail";
  import {export2Excel, getWidth} from "@/util";
  import print from  "print-js";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {EditPage,ElevatorDetail},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          factoryDate:"",
          realEstateName:"",
          realEstateStatus:true,
          elevatorStatus:true,
          maintenanceType:"as_need",
          companyId: loginUtil.getCompanyCode(),
        },
        senior: false,
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      changeMaintianType() {
        this.$confirm("此操作将解绑物联网设备，并将保养类型修改为安规保养，确定是否更改?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      },
      sort() {
        this.filter.orderColumnName="useStatus";
        this.filter.asc = this.asc;
        this.asc = this.asc?0:1;
        this.getList();
      },
      exportExcelData() {
        const title={
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"电梯在使用单位内部编号",
          regCode:"注册代码",
          regDate:"注册日期",
          maintenanceTypeDesc:"维保类型",
          useTypeDesc:"用途分类",
          brandName:"品牌",
          modelName:"型号",
          factoryCode:"出厂编号",
          useStatus:"电梯使用状态",
          totalFloor :"电梯层数",
          totalStation :"电梯站数",
        };
        this.$http.get("elevator/archive/common/page",{...this.filter,pageSize:3000000}).then(res=>{

          export2Excel(title,res.records,`电梯档案-常规信息-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.regCode}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.regCode + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      goToDetail(elevatorId) {
        this.$router.push({path:"/elevator/Elevatordetail",query:{elevatorId}});
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {
        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },
      change(id) {
        this.$http.post(`elevator/elevator/status/${id}`).then(()=>{
          this.getList();
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>
