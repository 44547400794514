<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','批量修改电梯层站楼'):$l('company.add','批量修改电梯层站楼')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <!--      <el-form-item label="设备状态" prop="status">-->
      <!--        <vm-dict-select v-model="model.status" type="iotDeviceStatus"></vm-dict-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="是否有机房" prop="machineRoom">
        <el-select v-model="model.machineRoom">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="电梯层数" prop="totalFloor">
        <el-input v-model="model.totalFloor"></el-input>
      </el-form-item>
      <el-form-item label="电梯站数" prop="totalStation">
        <el-input v-model="model.totalStation"></el-input>
      </el-form-item>
      <el-form-item label="电梯门数" prop="totalDoor">
        <el-input v-model="model.totalDoor"></el-input>
      </el-form-item>
        
      <el-form-item label="地上楼层计数" prop="aboveGroundFloorCount">
        <el-input-number
          v-model="model.aboveGroundFloorCount"
          controls-position="right"
          :min="0"
          :precision="0"
          style="width: 100%"
          @change="updateFloors"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="地下楼层计数" prop="underGroundFloorCount">
        <el-input-number
          v-model="model.underGroundFloorCount"
          controls-position="right"
          :min="-2"
          :max="0"
          :precision="0"
          style="width: 100%"
          @change="updateFloors"
        ></el-input-number>
      </el-form-item>
      <el-table
        :data="model.floors"
        label-position="left"
        class="demo-table-expand">
        <el-table-column prop="name" label="物理楼层" align="center" width="170">
        </el-table-column>

        <el-table-column prop="displayName" label="标识楼层" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.displayName"
              controls-position="right"
              :precision="0"
              style="width: 100%"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          elevatorIds: [],
          aboveGroundFloorCount: 0,
          underGroundFloorCount: 0,
          floors: [],
        },
      };
    },
    methods: {
      open(selectList) {
        const arr = [];
        selectList.forEach(item=>{
          arr.push(item.id);
        });
        this.model.elevatorIds = arr;
        this.dialogVisible = true;
        // this.getData();
      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("/elevator/base/factory/floor", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      updateFloors() {
        console.log(169,this.model.aboveGroundFloorCount-this.model.underGroundFloorCount);
        const count = this.model.aboveGroundFloorCount-this.model.underGroundFloorCount;
        if (count!==this.model.floors.length) {
          this.model.floors=[];
          for (let i = 0; i < count; i++) {
            if (i+this.model.underGroundFloorCount<0) {
              this.model.floors.push({
                name:i+1,
                displayName:i+this.model.underGroundFloorCount,
              });
            }else {
              this.model.floors.push({
                name:i+1,
                displayName:i+this.model.underGroundFloorCount+1,
              });
            }
          }
        }

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>